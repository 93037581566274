import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { PUBLIC_ROUTES } from "routes/routes";

export default function DontHaveAccount() {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { query } = router;

  const { invitationCode, redirect } = query;

  return (
    <div className="mt-8 w-full pb-3">
      <div className="flex w-full justify-center justify-items-center text-center">
        <p>{t("¿Aún no tienes tu cuenta?")}</p>
        <a
          className="primary ml-1 self-center font-semibold"
          style={{ cursor: "pointer" }}
          onClick={() =>
            router.push({
              pathname: PUBLIC_ROUTES.register.path,
              query: {
                redirect: redirect as string,
                invitationCode: invitationCode as string,
              },
            })
          }
          aria-hidden
        >
          {t("Crear cuenta")}
        </a>
      </div>
    </div>
  );
}
