"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import {
  NO_ORGANIZATION_REDIRECT,
  RECOVERY_PASSWORD_WITH_TOKEN,
  REFRESH_TOKEN_PERSIST,
  USER_LANG,
  USER_TOKEN_PERSIST,
} from "@constants/global";
import { PasswordInputV2 } from "components/PasswordInput/v2";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import { gqlCoins, gqlUser } from "gql";
import {
  IMicrosoftLoginResponse,
  ISocialNetworkLogInInput,
  ISocialNetworkLogInRes,
} from "gql/User/mutations";
import { ArrowRight, ComputerIcon } from "lucide-react";
import setLanguage from "next-translate/setLanguage";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { isEmail } from "react-multi-email";
import { PUBLIC_ROUTES } from "routes/routes";
import { forceRefetchQueries } from "settings/apollo";
import { IUser } from "types/User.types";
import { getQueryOperator } from "utils/helpers";
import LinkedInButtonAurora from "./LinkedIn";

interface ILoginForm {
  callback?: (error?: string) => void;
  redirect?: string;
  invitationCode?: string;
}

const LoginFormAurora = (props: ILoginForm) => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const { callback, invitationCode, redirect } = props;
  const { instance } = useMsal();
  const { vendor } = useVendor();

  const { setRefreshToken, setAccessToken, setUser, setBasketProducts } =
    useUserStoreContext((store) => ({
      setRefreshToken: store.setRefreshToken,
      setAccessToken: store.setAccessToken,
      setUser: store.setUser,
      setBasketProducts: store.setBasketProducts,
    }));

  const [getUserBasket] = useLazyQuery(gqlCoins.queries.USER_BASKET, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: ({ basket }) => {
      const { basketMemory } = basket;
      setBasketProducts(
        basketMemory.map(({ product, quantity }) => ({
          product,
          quantity,
        })) || []
      );
    },
  });

  const [loginWithMicrosoft, { loading: microsoftLoading }] = useMutation<
    { loginWithMicrosoft: IMicrosoftLoginResponse },
    { microsoftLogInInput: ISocialNetworkLogInInput }
  >(gqlUser.mutations.LOGIN_WITH_MICROSOFT, {
    refetchQueries: [getQueryOperator(gqlUser.queries.GET_FULL_USER)],
    awaitRefetchQueries: true,
    onCompleted({ loginWithMicrosoft }) {
      onCompletedLogin(loginWithMicrosoft);

      if (callback) {
        callback();
        return;
      }

      // * if the user came from an invitation link, redirect to inv
      if (invitationCode) {
        router.push({
          pathname: PUBLIC_ROUTES.invitation.path,
          query: {
            t: invitationCode,
            redirect: redirect as string,
          },
        });
        return;
      }
    },
  });

  const handleMicrosoftLogin = async () => {
    instance.loginRedirect({
      prompt: "select_account",
      scopes: ["User.Read"],
    });
  };

  useEffect(() => {
    instance.handleRedirectPromise().then((tokenResponse) => {
      if (tokenResponse) {
        loginWithMicrosoft({
          variables: {
            microsoftLogInInput: {
              accessToken: tokenResponse?.accessToken,
            },
          },
        });
      }
    });
  }, []);

  const [getUser, { data: getUserData }] = useLazyQuery<
    { user: IUser },
    { accessToken: string }
  >(gqlUser.queries.GET_FULL_USER, {
    fetchPolicy: "network-only",

    onCompleted: ({ user }) => {
      if (user && user.lang) {
        setLanguage(user.lang);
        localStorage.setItem(USER_LANG, user.lang);
      } else {
        setLanguage("en");
        localStorage.setItem(USER_LANG, "en");
      }

      setUser(user);

      if (user?.selectedVendor?.enableCoins) {
        getUserBasket();
      }
    },
  });

  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    gqlUser.queries.LOGIN,
    {
      onCompleted({ login }) {
        onCompletedLogin(login);
      },
      onError({ message }) {
        if (callback) callback(message);
      },
    }
  );

  const onCompletedLogin = (login: ISocialNetworkLogInRes) => {
    const { accessToken, refreshToken } = login;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    localStorage.setItem(USER_TOKEN_PERSIST, accessToken);
    localStorage.setItem(REFRESH_TOKEN_PERSIST, refreshToken);

    getUser({
      variables: {
        accessToken,
      },
    });
  };

  const {
    handleSubmit: handleLoginSubmit,
    control: loginControl,
    formState: { errors },
  } = useForm();

  const handleLogin = async ({ email, password }) => {
    try {
      const { data } = await login({
        variables: {
          loginInput: {
            email,
            password,
          },
        },
      });

      if (data && data.login) {
        onCompletedLogin(data.login);
      }
    } catch (e) {
      console.error("Login error:", e);
    }
  };

  const onRecoverPassword = () => {
    router.push(RECOVERY_PASSWORD_WITH_TOKEN);
  };

  const processUserData = async (userData) => {
    const {
      user: { vendorList },
    } = userData;

    if (callback) {
      callback();
      return;
    }

    // * if the user came from an invitation link, redirect to inv
    if (invitationCode) {
      router.push({
        pathname: PUBLIC_ROUTES.invitation.path,
        query: {
          t: invitationCode,
        },
      });
      return;
    }

    await forceRefetchQueries([gqlUser.queries.GET_FULL_USER]);

    if (!vendorList?.length) {
      router.push(NO_ORGANIZATION_REDIRECT);
    }
  };

  useEffect(() => {
    if (getUserData) {
      processUserData(getUserData);
    }
  }, [getUserData]);

  return (
    <div className="grid w-full justify-center">
      <form onSubmit={handleLoginSubmit(handleLogin)} className="space-y-6">
        {!vendor?.onlyAdLogin && (
          <>
            <div className="space-y-2">
              <h3 className="pb-4 text-center text-xl font-semibold md:text-2xl">
                <span className="wave">👋</span>{" "}
                {t("Hola, te damos la bienvenida")}
              </h3>

              <Label htmlFor="email" className="font-semibold">
                {t("Ingresa tu correo electrónico")}
              </Label>
              <Controller
                name="email"
                control={loginControl}
                defaultValue=""
                rules={{
                  required: true,
                  validate: (value) => isEmail(value),
                }}
                render={({ field }) => (
                  <Input
                    id="email"
                    placeholder={t("Ingresa tu correo electrónico")}
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <p className="text-sm text-red-500">
                  {t("Debes ingresar un e-mail válido")}
                </p>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="password" className="font-semibold">
                {t("Ingresa tu contraseña")}
              </Label>
              <Controller
                name="password"
                defaultValue=""
                control={loginControl}
                rules={{ required: true }}
                render={({ field: { ...rest }, fieldState }) => (
                  <PasswordInputV2
                    {...rest}
                    {...fieldState}
                    placeholder={t("Ingresa tu contraseña2")}
                  />
                )}
              />
              {errors.password && (
                <p className="text-sm text-red-500">
                  {t("Debes ingresar una contraseña")}
                </p>
              )}
            </div>
          </>
        )}

        {loginError && (
          <p className="text-center text-red-500">{loginError.message}</p>
        )}

        <div className="grid w-full gap-4">
          {!vendor?.onlyAdLogin && (
            <div className="flex items-center justify-center">
              <Button
                variant="link"
                onClick={onRecoverPassword}
                className="flex min-h-[40px] w-full gap-2"
                type="button"
              >
                {t("No recuerdo mi contraseña")}
                <ArrowRight strokeWidth={1} size={16} />
              </Button>
            </div>
          )}
          {!vendor?.onlyAdLogin && (
            <>
              <Button
                className="flex min-h-[40px] w-full gap-2"
                type="submit"
                disabled={loginLoading}
              >
                {loginLoading ? t("Ingresando...") : t("Ingresar a tu cuenta")}
                <ArrowRight strokeWidth={1} size={16} />
              </Button>

              {vendor?.enableLoginWithLinkedin && (
                <LinkedInButtonAurora
                  onCompleted={onCompletedLogin}
                  parentLoading={loginLoading}
                />
              )}
            </>
          )}

          {vendor?.enableMicrosoftLogin && (
            <Button
              variant="outline"
              className="w-full"
              onClick={handleMicrosoftLogin}
              disabled={microsoftLoading}
              type="button"
            >
              <ComputerIcon className="mr-2 h-4 w-4" />
              {microsoftLoading
                ? t("Ingresando")
                : t("Inicio de sesión con Microsoft")}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginFormAurora;
