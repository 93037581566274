import { Button } from "@/components/ui/button";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  LOGO_DARK,
  LOGO_LIGHT,
  REFRESH_TOKEN_PERSIST,
  USER_TOKEN_PERSIST,
} from "@constants/global";
import ToggleLang from "components/ToggleLang/ToggleLang";
import ToggleTheme from "components/ToggleTheme/ToggleTheme";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import { gqlUser } from "gql";
import { ISocialNetworkLogInRes } from "gql/User/mutations";
import useTranslation from "next-translate/useTranslation";
import router from "next/router";
import toast from "react-hot-toast";
import DontHaveAccount from "../../classic/Login/components/dontHaveAccount";
import ImageVendorLogo from "../../classic/Login/components/imageVendorLogo";
import LayoutVideoAurora from "../layout/layoutVideoAurora";
import LoginFormAurora from "./components/LoginFormAurora";
import { PRIVATE_ROUTES } from "routes/routes";
import Image from "next/image";
import { useTheme } from "next-themes";

export default function LoginAurora() {
  const { query } = router;
  const { t } = useTranslation("common");
  const { theme } = useTheme();
  const { invitationCode, redirect, loginsource } = query;
  const { vendor } = useVendor();

  const { setUser, setAccessToken, setRefreshToken } = useUserStoreContext(
    (store) => ({ ...store })
  );

  const parseRedirect = decodeURIComponent(loginsource as string);

  const firstLogin = parseRedirect?.split("firstLogin=")[1];

  const [getUser] = useLazyQuery(gqlUser.queries.GET_FULL_USER, {
    fetchPolicy: "network-only",
    onCompleted({ user }) {
      setUser(user);
    },
    onError({ message }) {
      toast.error(message);
    },
  });

  const onCompletedLogin = (login: ISocialNetworkLogInRes) => {
    const { accessToken, refreshToken } = login;

    localStorage.setItem(USER_TOKEN_PERSIST, `${accessToken}`);
    localStorage.setItem(REFRESH_TOKEN_PERSIST, `${refreshToken}`);

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    // * if firstLogin sent to onboarding
    if (firstLogin) {
      getUser({
        variables: {
          accessToken,
        },
      }).then(() => {
        router.push(PRIVATE_ROUTES.appOnboarding.path);
      });
    } else {
      getUser({
        variables: {
          accessToken,
        },
      });
    }
  };

  const [login] = useMutation(gqlUser.queries.LOGIN_WITH_TOKEN, {
    onCompleted({ loginWithToken }) {
      onCompletedLogin(loginWithToken);
    },
    onError({ message }) {
      toast.error(message);
    },
  });

  const handleLogin = () => {
    login({
      variables: {
        token: firstLogin as string,
      },
    });
  };

  return (
    <LayoutVideoAurora renderVideo={false}>
      {/* Right column with content */}

      <div>
        {!firstLogin && (
          <>
            <div className="mb-6">{vendor?.logo && <ImageVendorLogo />}</div>

            {!vendor?.logo && (
              <div className="hidden justify-center pb-20 md:flex">
                <Image
                  src={theme === "dark" ? LOGO_DARK : LOGO_LIGHT}
                  width={180}
                  height={40}
                  className="justify-start"
                  alt="logo"
                />
              </div>
            )}

            <LoginFormAurora
              {...{
                redirect: redirect as string,
                invitationCode: invitationCode as string,
              }}
            />
          </>
        )}

        {firstLogin && (
          <div className="flex flex-col items-center justify-center gap-2">
            <h3 className="text-2xl font-bold">
              {t("Bienvenido a Groowcity")}
            </h3>
            <p className="text-center">
              {t("Hemos creado tu organización")}{" "}
              <b>{window.location.hostname}.</b> <br />
              {t(
                "Haz clic en Ir a la Organización para disfrutar de la plataforma"
              )}
            </p>
            <Button
              onClick={handleLogin}
              className="flex min-h-[40px] w-2/3 gap-2 md:w-1/4"
            >
              {t("Ir a la Organización")} 🚀
            </Button>
          </div>
        )}

        {!vendor?.onlyAdLogin && !firstLogin && <DontHaveAccount />}

        <div className="mt-2 flex w-full items-center justify-center gap-2">
          <ToggleLang />
          <ToggleTheme />
        </div>
      </div>
    </LayoutVideoAurora>
  );
}
