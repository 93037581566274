import { useVendor } from "context/vendor/vendor.provider";
import Image from "next/image";

export default function ImageVendorLogo() {
  const { vendor } = useVendor();

  if (vendor?.logo) {
    return (
      <div className="relative m-auto grid h-auto w-full max-w-[50%] justify-center rounded-lg">
        <Image
          src={vendor?.logo}
          width={200}
          height={200}
          priority
          sizes="100%"
          alt="Vendor logo"
          className="rounded-lg object-scale-down"
        />
      </div>
    );
  }
}
