import LoginAurora from "../aurora/Login/LoginAurora";
import LoginClassic from "../classic/Login/LoginClassic";

// Define el tipo del componente Login
interface LoginComponent {
  Classic: typeof LoginClassic;
  Aurora: typeof LoginAurora;
}

// Crear el contenedor lógico
const Login: LoginComponent = {
  Classic: LoginClassic,
  Aurora: LoginAurora,
};

export default Login;
