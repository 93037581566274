import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ILLUSTRATION_LOGIN,
  LOGO_DARK,
  LOGO_LIGHT,
  REFRESH_TOKEN_PERSIST,
  USER_TOKEN_PERSIST,
  WIDTH_MD,
} from "@constants/global";
import { useWindowSize, useWindowWidth } from "@react-hook/window-size";
import LeftImagePublic from "components/Pages/LeftImagePublic/LeftImagePublic";
import { APP_VERSION } from "components/Roadmap/ItemPathNode/constants";
import ToggleLang from "components/ToggleLang/ToggleLang";
import ToggleTheme from "components/ToggleTheme/ToggleTheme";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import { gqlUser } from "gql";
import { ISocialNetworkLogInRes } from "gql/User/mutations";
import { useTheme } from "next-themes";
import useTranslation from "next-translate/useTranslation";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import toast from "react-hot-toast";
import { Button, Col, Row } from "rsuite";
import LoginForm from "./Form";
import DontHaveAccount from "./components/dontHaveAccount";
import ImageVendorLogo from "./components/imageVendorLogo";

const LoginClassic = () => {
  const router = useRouter();
  const { query } = router;
  const { invitationCode, redirect, loginsource } = query;
  const { t } = useTranslation("common");
  const { vendor } = useVendor();
  const [confetti, setConfetti] = useState<boolean>(false);
  const [run, setRun] = useState<boolean>(false);
  const wSize = useWindowSize();

  const { setUser, setAccessToken, setRefreshToken } = useUserStoreContext(
    (store) => ({ ...store })
  );
  // * url decode redirect
  const parseRedirect = decodeURIComponent(loginsource as string);

  // * extract first login token from parseRedirect
  const firstLogin = parseRedirect?.split("firstLogin=")[1];

  const [getUser] = useLazyQuery(gqlUser.queries.GET_FULL_USER, {
    fetchPolicy: "network-only",
    onCompleted({ user }) {
      setUser(user);
    },
    onError({ message }) {
      toast.error(message);
    },
  });

  const onCompletedLogin = (login: ISocialNetworkLogInRes) => {
    const { accessToken, refreshToken } = login;

    localStorage.setItem(USER_TOKEN_PERSIST, `${accessToken}`);
    localStorage.setItem(REFRESH_TOKEN_PERSIST, `${refreshToken}`);

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    getUser({
      variables: {
        accessToken,
      },
    });
  };

  const [login, { loading: loginLoading }] = useMutation(
    gqlUser.queries.LOGIN_WITH_TOKEN,
    {
      onCompleted({ loginWithToken }) {
        onCompletedLogin(loginWithToken);
      },
      onError({ message }) {
        toast.error(message);
      },
    }
  );

  const handleLogin = () => {
    login({
      variables: {
        token: firstLogin as string,
      },
    });
  };

  useEffect(() => {
    if (firstLogin) {
      setConfetti(true);
      setRun(true);

      setTimeout(() => {
        setConfetti(false);
      }, 5000);
    }
  }, [firstLogin]);

  const wWidth = useWindowWidth();
  const isMd = wWidth >= WIDTH_MD;
  const { theme } = useTheme();

  return (
    <div className="w-full">
      <div
        className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white"
        style={{ height: 4, minHeight: 4 }}
      />
      <Confetti {...wSize} run={run} recycle={confetti} />
      <Row className="m-0">
        {isMd && (
          <Col className="h-40vh w-screen p-0 lg:h-screen lg:w-5/12">
            <LeftImagePublic
              backgroundsvg={
                !!vendor?.loginBackgroundImage
                  ? vendor?.loginBackgroundImage
                  : ILLUSTRATION_LOGIN
              }
            />
          </Col>
        )}

        {!isMd && (
          <Col className="flex w-full items-center justify-center border pt-12">
            <Image
              src={theme === "dark" ? LOGO_DARK : LOGO_LIGHT}
              width={180}
              height={40}
              className="justify-start"
              alt="logo"
            />
          </Col>
        )}
        <Col className="animate__animated animate__fadeIn flex w-screen flex-col items-center justify-center pt-4 md:pt-12 lg:h-screen lg:w-7/12 lg:pt-0">
          <div className="flex w-5/6 flex-col items-start justify-center md:w-4/6">
            <div className="mt-4 w-full">
              {!firstLogin && (
                <>
                  <div className="mb-6">
                    <ImageVendorLogo />
                  </div>
                  <LoginForm
                    {...{
                      redirect: redirect as string,
                      invitationCode: invitationCode as string,
                    }}
                  />
                </>
              )}

              {firstLogin && (
                <div className="flex flex-col items-center justify-center">
                  <h3 className="text-2xl font-bold">
                    {t("Bienvenido a Groowcity")}
                  </h3>
                  <p className="text-center">
                    {t("Hemos creado tu organización")}{" "}
                    <b>{window.location.hostname}.</b> <br />
                    {t(
                      "Haz clic en Ir a la Organización para disfrutar de la plataforma"
                    )}
                  </p>
                  <Button
                    className="mt-4 w-2/3"
                    appearance="primary"
                    size="lg"
                    block
                    loading={loginLoading}
                    onClick={handleLogin}
                  >
                    {t("Ir a la Organización")} 🚀
                  </Button>
                </div>
              )}
            </div>

            {!vendor?.onlyAdLogin && !firstLogin && <DontHaveAccount />}

            <div className="mt-2 flex w-full items-center justify-center gap-2">
              <ToggleLang />
              <ToggleTheme />
            </div>

            {/* app version right bottom */}
            <div className="absolute bottom-0 hidden p-4 text-xs text-gray-400 dark:text-gray-500 md:bottom-0 md:right-0 md:block">
              {APP_VERSION}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginClassic;
