import { Button } from "@/components/ui/button";
import { useMutation } from "@apollo/client";
import SVG from "components/SVG";
import { gqlUser } from "gql";
import {
  ISocialNetworkLogInInput,
  ISocialNetworkLogInRes,
} from "gql/User/mutations";
import { ILoginResponse } from "gql/User/queries";
import useTranslation from "next-translate/useTranslation";
import { useLinkedIn } from "react-linkedin-login-oauth2";

interface ILinkedInButtonProps {
  parentLoading?: boolean;
  onCompleted: (loginWithLinkedin: ILoginResponse) => void;
}
const LinkedInButtonAurora = (props: ILinkedInButtonProps) => {
  const { onCompleted, parentLoading } = props;
  const [loginWithLinkedin, { loading }] = useMutation<
    { loginWithLinkedIn: ISocialNetworkLogInRes },
    { linkedinLogInInput: ISocialNetworkLogInInput }
  >(gqlUser.mutations.LOGIN_WITH_LINKEDIN, {
    onCompleted: ({ loginWithLinkedIn }) => {
      onCompleted(loginWithLinkedIn);
    },
  });
  const { t } = useTranslation("common");
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.NEXT_PUBLIC_LINKEDIN_CLIENTID,
    redirectUri: `${
      typeof window === "object" && window.location.origin
    }/linkedin`,
    scope: "r_emailaddress r_liteprofile",
    onSuccess: async (code) => {
      loginWithLinkedin({
        variables: {
          linkedinLogInInput: {
            accessToken: code,
          },
        },
      });
    },
    onError: () => {},
  });

  return (
    <Button
      onClick={linkedInLogin}
      variant={"outline"}
      loading={loading}
      disabled={parentLoading}
      className="flex max-h-[40px] w-full gap-2"
    >
      <div className="flex items-center justify-center py-2">
        <SVG src="/icon/linkedin.svg" width={20} height={20} />
        <span className="px-2">{t("Ingresar con LinkedIn")}</span>
      </div>
    </Button>
  );
};

export default LinkedInButtonAurora;
